<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-form
      ref="checklistform"
      @submit.prevent="submitChecklist"
      lazy-validation
      v-model="valid"
    >
      <v-card class="c-bg-gray">
        <v-toolbar dark color="primary">
          <v-btn
            icon
            dark
            @click="dialog = false"
            :to="{ name: 'BookVehicle' }"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Checklist</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="tw-mt-4 tw-mb-10">
          <v-card class="tw-px-2 md:tw-px-6">
            <v-card-title>
              <h3>User Details</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-row class="my-5 ml-3">
              <v-col col="12">
                Insured’s Name:
                {{ scanObject(bookedVehicle, 'clientInfo.name') }}
              </v-col>
              <v-col col="12">
                Reg No:
                {{
                  scanObject(bookedVehicle, 'vehicleInfo.registrationNumber')
                }}
              </v-col>
              <v-col col="12">
                Phone Number:
                {{ scanObject(bookedVehicle, 'clientInfo.phoneNumber') }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 ml-3">
              <v-col><strong>Checklist Preparation</strong></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 ml-3">
              <v-col>
                Submit your Client’s Checklist form to confirm the list of
                things that came with the vehicle before repairs, which will
                also be available after repairs.
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 ml-3">
              <v-col
                v-for="checklistItem in checklistItemsArray"
                :key="checklistItem"
                cols="12"
                md="6"
                lg="4"
                class="mx-auto"
              >
                <p class="pt-6 pr-5">
                  {{ checklistItem.split('_').join(' ').toUpperCase() }}:
                </p>
                <v-radio-group
                  v-if="checklistItemsArray.indexOf(checklistItem) >= 0"
                  :v-model="
                    newSelection[checklistItemsArray.indexOf(checklistItem)]
                  "
                  row
                  :name="checklistItem"
                  :rules="[
                    (v) =>
                      v === true ||
                      v === false ||
                      `This ${checklistItem} field is required`,
                  ]"
                >
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
                <v-text-field
                  label="Comment"
                  :name="checklistItem + '_comment'"
                  v-model="
                    newComments[checklistItemsArray.indexOf(checklistItem)]
                  "
                ></v-text-field>

                <!-- Upload and Camera Buttons -->
                <div class="tw-flex tw-gap-2 tw-items-center tw-mt-3">
                  <template
                    v-if="
                      newPhotos[checklistItem] &&
                      newPhotos[checklistItem].length > 0
                    "
                  >
                    <v-btn
                      text
                      class="tw-font-roboto tw-text-lg tw-font-bold"
                      color="#00A36C"
                      @click="
                        openUploadModal({
                          label: checklistItem,
                          photos: newPhotos[checklistItem],
                          mode: 'edit',
                        })
                      "
                    >
                      Edit Photo
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      icon
                      @click="
                        openUploadModal({
                          label: checklistItem,
                          mode: 'upload',
                        })
                      "
                    >
                      <v-icon color="#114CAC">mdi-cloud-upload</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="
                        openUploadModal({
                          label: checklistItem,
                          mode: 'camera',
                        })
                      "
                    >
                      <v-icon color="#114CAC">mdi-camera</v-icon>
                    </v-btn>
                  </template>
                </div>
              </v-col>
            </v-row>

            <v-divider class="my-5"></v-divider>

            <!-- Photo Display -->
            <PhotoDisplay
              :checklist_photos="checklistPhotos"
              title="Booking Photos"
              comment="The uploaded photos play a crucial role in documenting the vehicle's condition from its arrival at the assessment center to its departure for repair. Please ensure all necessary details are thoroughly recorded."
              @view-photo="openPhotoViewer"
              @edit-photo="openPhotoEditor"
              @delete-photo="handlePhotoRemoved"
            />

            <v-divider class="my-5"></v-divider>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  id="submitCheckListBtn"
                  large
                  color="primary"
                  type="submit"
                >
                  Submit Checklist
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-card>

      <!-- Upload Photo Modal -->
      <UploadPhotoModal
        ref="uploadPhotoModal"
        :isOpen="showModal"
        :mode="uploadMode"
        :label="currentLabel"
        :available="currentSelection"
        :photo="currentPhotos"
        @close="closeUploadModal"
        @photo-added="handlePhotoAdded"
        @photo-removed="handlePhotoRemoved"
        @photo-edited="handlePhotoEdited"
      />
    </v-form>
  </v-dialog>
</template>

<script>
import UploadPhotoModal from '../../../components/assessment-center/bids-submitted/UploadPhotoModal.vue'
import PhotoDisplay from '../../../components/assessment-center/bids-submitted/UploadedPhotos.vue'
import { checklistItemsArray } from '@/data/checklistItems'
import axios from 'axios'

export default {
  components: { UploadPhotoModal, PhotoDisplay },
  data() {
    return {
      valid: true,
      dialog: true,
      checklistItemsArray: checklistItemsArray,
      showModal: false,
      uploadMode: '',
      currentLabel: '',
      currentPhotos: [],
      newPhotos: {},
      checklistPhotos: [],
      newSelection: [],
      newComments: [],
      currentSelection: true,
    }
  },
  computed: {
    bookedVehicle() {
      return JSON.parse(this.scanObject(this.$route, 'query.booking', {}))
    },
  },
  methods: {
    openUploadModal({ label, photos = [], mode }) {
      const itemIndex = this.checklistItemsArray.indexOf(label)
      const available = this.newSelection[itemIndex]

      this.uploadMode = mode
      this.currentLabel = label
      this.currentPhotos = photos
      this.currentSelection = available

      if (mode === 'edit' && photos.length > 0) {
        const photoToEdit = photos[0]
        this.currentPhotos = {
          label,
          image_base_64: photoToEdit.image_base_64 || photoToEdit.image_url,
          image_url: photoToEdit.image_url || '',
          available: photoToEdit.available || false,
          comment: photoToEdit.comment || '',
        }
      } else {
        this.currentPhotos = null
      }
      this.showModal = false
      this.$nextTick(() => {
        this.showModal = true
      })
    },

    closeUploadModal() {
      this.showModal = false
      this.uploadMode = ''
      this.currentLabel = ''
      this.currentComment = ''
      this.currentPhotos = []
      this.currentSelection = false
    },
    handlePhotoAdded(data) {
      const { label, photos } = data
      if (!this.newPhotos[label]) {
        this.newPhotos[label] = []
      }
      const photoEntries = photos.map((photo) => ({
        label,
        comment: photo.comment || 'No comment provided',
        image_url: photo.url || '',
        image_base_64: photo.image_base_64,
        available: this.currentSelection,
      }))
      this.newPhotos[label] = [...this.newPhotos[label], ...photoEntries]
      this.updateChecklistPhotos()
    },

    handlePhotoRemoved(data) {
      const { label, photo } = data

      if (this.newPhotos[label]) {
        this.newPhotos[label] = this.newPhotos[label].filter(
          (existingPhoto) =>
            existingPhoto.image_base_64 !== photo.image_base_64 &&
            existingPhoto.image_url !== photo.image_url
        )
        if (!this.newPhotos[label].length) {
          delete this.newPhotos[label]
        }
        this.updateChecklistPhotos()
      }
    },
    handlePhotoEdited(data) {
      const { label, photo } = data

      if (!this.newPhotos[label]) {
        return
      }
      photo.available = this.currentSelection

      this.newPhotos[label] = this.newPhotos[label].map((existingPhoto) => {
        if (
          existingPhoto.image_base_64 === photo.image_base_64 ||
          existingPhoto.image_url === photo.image_url
        ) {
          return {
            ...existingPhoto,
            ...photo,
          }
        }
        return existingPhoto
      })

      this.updateChecklistPhotos()
    },

    updateChecklistPhotos() {
      this.checklistPhotos = Object.keys(this.newPhotos).map((label) => ({
        label,
        photos: this.newPhotos[label],
      }))
    },

    openPhotoViewer(photo) {
      if (photo) {
        this.$store.commit('SNACKBAR', {
          showing: true,
          text: 'Opening photo...',
          color: 'info',
        })
      }
    },
    openPhotoEditor({ label, photos, comment }) {
      this.openUploadModal({
        label,
        photos,
        comment,
        mode: 'edit',
      })
    },
    submitChecklist(event) {
      if (!this.$refs.checklistform.validate()) {
        this.$store.commit('SNACKBAR', {
          showing: true,
          text: 'Please try again.',
          color: 'error',
        })
        return
      }

      const bookingId = this.bookedVehicle.id
      const formData = Object.fromEntries(new FormData(event.target))
      const checklistPhotosPayload = this.checklistPhotos.reduce(
        (acc, item) => {
          return acc.concat(item.photos)
        },
        []
      )

      const payload = {
        ...formData,
        checklist_photos: checklistPhotosPayload,
      }
      axios
        .post(`/motor-assessment/api/checklist/${bookingId}`, payload)
        .then(() => {
          this.$store.commit('SNACKBAR', {
            showing: true,
            text: 'Checklist submitted successfully',
            color: 'success',
          })
          this.$router.push({ name: 'BookVehicle' })
        })
        .catch((error) => {
          this.$store.commit('SNACKBAR', {
            showing: true,
            text: error,
            color: 'error',
          })
        })
    },
  },
}
</script>
